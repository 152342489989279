( function( $, window, document, undefined ) {

	const $floatingButton = $('.floating-button');
	const $footer = $('.site-footer');
	var footerPos;
	var buttonPos;

	$(window).on( 'load scroll', throttle( function() {

		footerPos = $footer.offset().top;
		buttonPos = $floatingButton.offset().top;

		if ( !$floatingButton.hasClass('hide') && buttonPos >= footerPos ) 
		{
			$floatingButton.addClass('hide');
		}
		
		else if ( $floatingButton.hasClass('hide') && buttonPos < footerPos )
		{
			$floatingButton.removeClass('hide');	
		}

	}, 150 ) );

} )( jQuery, window, document );