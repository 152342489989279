( function( $, window, document, undefined ) {

	const $hamburger = $('.hamburger');
	const $nav = $('.site-nav');

	$hamburger.on('click', function() {
		if ( $(this).hasClass('is-active') ) 
		{
			CloseMenu();
		}
		else
		{
			OpenMenu();
		}
	});

	function OpenMenu() {
		$hamburger.addClass('is-active');
		$nav.addClass('is-visible');
		bodyScrollLock.disableBodyScroll($nav, {reserveScrollBarGap: true});
	}

	function CloseMenu() {
		$hamburger.removeClass('is-active');
		$nav.removeClass('is-visible');
		bodyScrollLock.enableBodyScroll($nav);
	}

} )( jQuery, window, document );
