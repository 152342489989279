// Initialise carousel components

( function( $, window, document, undefined ) {

	let $carousel = $('.js-carousel'),
			options = {
				autoplay: true,
				autoplaySpeed: 4000,
				pauseOnHover: false,
				dots: true,
				arrows: false,
				infinite: true,
				adaptiveHeight: false,
				draggable: false
			};

	if ( $carousel.length ) {
		$carousel.slick( options );
	}


	var $heroCarousel = $('.hero__carousel');

	if ( $heroCarousel.length ) {
		$heroCarousel.slick({
			dots: false,
			arrows: false,
			fade: true,
			draggable: true,
			autoplay: true,
			autoplaySpeed: 2500,
			infinite: true,
			adaptiveHeight: false
		});
	}

	/**
	 * Special case - floorplan carousels
	 */
	let $floorplanCarousel = $('.js-floorplan-carousel'),
			floorplanOptions = {
				dots: false,
				arrows: false,
				infinite: true,
				adaptiveHeight: false,
				draggable: false,
				responsive: [
					{
						breakpoint: 650,
						settings: "unslick"
					}
				]
			};

	if ( $floorplanCarousel.length ) {
		$floorplanCarousel.slick( floorplanOptions );

		$(window).on( 'resize', throttle( (event) => {
			if ( !$floorplanCarousel.hasClass( 'slick-initialized' ) && windowWidth() > 650 ) {
				$floorplanCarousel.slick( floorplanOptions );
			}
		}, 150 ) );
	}

	$('.floorplans__button').on( 'click', function(){
		var action = $(this).data('slick-action');
		$floorplanCarousel.slick( action );
	});


} )( jQuery, window, document );
